import { useEffect, useState } from 'react'
import { Card, Flex } from '../../../../Components'
import { CustomHeader, TransferField } from '../Components'

const getTypeKeys = (item) => {
  if (item?.listSelectValue?.length > 0) {
    const options = []
    item?.listSelectValue.forEach((item) =>
      !options.some((el) => el.value === item?.value && el.label === item?.label) && options.push(item)
    )
    return { type: 'dropdown', options } // return unique options
  }
  if (item?.type === 'date') return { type: 'date' }
  if (item?.type === 'numeric') return { type: 'numeric', label: { it: item?.mainLabel + ' (numerico)' } }
  return {}
}

export const getCampi1a10 = (state) =>
  state?.transferInfo?.infoPax?.listOfPax?.[0]?.campi1a10?.map((item) => ({
    label: { it: item?.mainLabel },
    location: 'transferInfo.infoPax.listOfPax.0.campi1a10Found.' + item?.key,
    id: item?.key,
    ...getTypeKeys(item),
    notRequired: !state?.environmentInfo?.enableCampi1a10
  }))

export const Campi1a10Section = ({ state: [state, setState], readOnly }) => {
  const stateProp = [state, setState]
  const [getCampi1a10Items, setGetCampi1a10Items] = useState([])

  useEffect(() => {
    setGetCampi1a10Items(getCampi1a10(state))
  }, [getCampi1a10(state)?.length])

  if (!getCampi1a10Items?.length) return null
  return (
    <Card customHeader={<CustomHeader title='CAMPI 1 A 10' />} style={{ width: '100%', marginTop: 10 }}>
      <Flex fw wrap row>
        {getCampi1a10Items.map((item) => (
          <TransferField state={stateProp} item={item} readOnly={readOnly} key={item?.id} />
        ))}
      </Flex>
    </Card>
  )
}
