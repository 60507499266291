import { Button, Card, ChatMessages, Flex, Writer } from '../../../Components'
import { FC, invalidateQuery, useCQuery } from '../../../Services'
import { createNewProcess, getCorrectState } from './Functions'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../Provider/AuthProvider'

export const FormChat = ({ stateRef }) => {
  const { conversationId } = useParams()
  const { data: conversation = {}, isSuccess } = useCQuery(['conversation', conversationId])
  const [messages, setMessages] = useState([])
  const { user: { user = {} } = {} } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if ((messages.length < conversation?.messages?.length ||
      messages.map(m => m.attachments.length) < conversation?.messages?.map(m => m.attachments.length)) && isSuccess) {
      const travellerEmail = conversation?.traveller?.email
      setMessages(conversation?.messages?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        .map(({ message: messageText, sender, createdAt, attachments }) => ({ message: messageText, user: sender === 'traveller' ? travellerEmail : 'Nora', createdAt: new Date(createdAt), attachments })))
    }
  }, [conversation, isSuccess])

  const sendMessage = async (message) => {
    setMessages([...messages, { message, user: 'Nora', createdAt: new Date(), attachments: [] }])
    const newState = { ...getCorrectState(stateRef.current), operatorInfo: { messageToSend: message } }
    await createNewProcess(newState, conversationId, 'sendOperatorMessageFromForm')
    await FC.client.service('conversations').patch(conversationId, { status: 'open' })
    invalidateQuery(['conversation', 'conversations'])
  }

  const createTest = async () => {
    try {
      const res = await FC.service('autoTests').create({
        messages: [{ user: 'User', message: messages[0]?.message }],
        correctState: conversation?.lastProcessStep?.finalState
      })
      if (res) return window.growl.show({ severity: 'success', summary: 'Test creato', detail: 'Il test è stato creato con successo' })
    } catch (e) {
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Si è verificato un errore durante la creazione del test' })
    }
  }

  if (!isSuccess) return null
  return (
    <Flex fw>
      <Flex fw je row style={{ height: 30 }}>
        <Button label='Torna indietro' icon='left' onClick={() => navigate('/operatore/list')} />
        {user?.role === 'admin' &&
          <>
            <Button label='Vai a processi' icon='settings' onClick={() => navigate('/conversations/' + conversationId)} style={{ marginLeft: 10 }} />
            <Button label='Crea Test' icon='extreme' onClick={createTest} style={{ marginLeft: 10 }} />
          </>}
      </Flex>
      <Card style={{ padding: 10, borderRadius: 10, marginTop: 10, overflow: 'scroll', width: '100%' }}>
        <ChatMessages messages={messages} jsonVisualized={false} />
        {conversation?.status === 'handledByOperator' &&
          <Writer sendMessage={sendMessage} composerStyle={{ width: '100%', padding: 0 }} noAttachments noraDraft={stateRef.current.draftMessage} />}
      </Card>
    </Flex>
  )
}
