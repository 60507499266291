import React from 'react'
import { CheckBoxGroupItem, Flex, PanelText } from '../../../Components'
import { Divider } from 'primereact/divider'

const steps = ['Raccolta informazioni', 'Creazione trasferta e servizi', 'Creazione preventivo']

const StepChooseModeAI = ({ selectedSteps: [selectedSteps, setSelectedSteps], b4bEnabled = 0 }) => (
  <Flex fw>
    <PanelText value='Abilitazione step di avanzamento:' header />
    <Flex row wrap style={{ marginTop: 20, gap: 15 }}>
      <CheckBoxGroupItem steps={steps} i='0' selected={selectedSteps === '0'} onClick={() => setSelectedSteps('0')} />
      <CheckBoxGroupItem steps={steps} i='1' selected={selectedSteps === '1'} onClick={() => setSelectedSteps('1')} />
      {!!b4bEnabled && <CheckBoxGroupItem steps={steps} i='2' selected={selectedSteps === '2'} onClick={() => setSelectedSteps('2')} />}
    </Flex>
    <Divider />
  </Flex>
)

export default StepChooseModeAI
