import React from 'react'
import { Flex } from '../../../Components'
import CheckboxPanel from './CheckBoxPanel'

const Controlli = ({
  checkAutomaticHotel: [checkAutomaticHotel, setCheckAutomaticHotel],
  checkControlliIA: [checkControlliIA, setCheckControlliIA],
  checkPubblicaLoStesso: [checkPubblicaLoStesso, setCheckPubblicaLoStesso],
  checkControllaPreventivi: [checkControllaPreventivi, setCheckControllaPreventivi],
  checkChiediMezzoDiTrasporto: [checkChiediMezzoDiTrasporto, setCheckChiediMezzoDiTrasporto],
  signNora: [signNora, setSignNora],
  sendFinalMessage: [sendFinalMessage, setSendFinalMessage],
  checkMeteo: [checkMeteo, setCheckMeteo],
  selectedSteps
}) => (
  <Flex fw as js wrap style={{ gap: 10 }}>
    <CheckboxPanel
      isFirst
      checked={checkAutomaticHotel}
      onChange={setCheckAutomaticHotel}
      headerValue='Crea automaticamente servizio hotel'
      description='Se attivato, verrà creato un servizio hotel per la destinazione della trasferta se essa è di più giorni, con le date di check-in e check-out corrispondenti al periodo della trasferta stessa.'
    />
    <CheckboxPanel
      checked={checkMeteo}
      onChange={setCheckMeteo}
      headerValue='Aggiungi le informazioni meteo al messaggio'
      description='Se attivato, il messaggio includerà le previsioni meteo relative al periodo della trasferta, fornendo dettagli utili per la pianificazione.'
    />
    <CheckboxPanel
      checked={checkControlliIA}
      onChange={setCheckControlliIA}
      headerValue="Verifica e conferma i dati generati dall'IA"
      description="Dopo la raccolta dei dati, l’operatore dovrà controllare e confermare le informazioni inserite dall’intelligenza artificiale nella sezione 'Form Operatore'"
    />
    <CheckboxPanel
      checked={checkChiediMezzoDiTrasporto}
      onChange={setCheckChiediMezzoDiTrasporto}
      headerValue='Richiedi il mezzo di trasporto se non specificato'
      description="Se attivato, verrà chiesto all'utente se desidera un servizio aereo o ferroviario per raggiungere la destinazione nel caso in cui non sia già stato indicato."
    />
    <CheckboxPanel
      checked={signNora}
      onChange={setSignNora}
      headerValue='Abilita messaggi firmati con nome Nora'
      description="Se attivato, i messaggi inviati all'utente saranno firmati con il nome 'Nora' per garantire un'esperienza più personalizzata."
    />
    <CheckboxPanel
      checked={sendFinalMessage}
      onChange={setSendFinalMessage}
      headerValue='Abilita invio di un messaggio finale al richiedente della trasferta'
      description='Se attivato, viene inviato un messaggio informativo al richiedente della trasferta al termine della procedura. (testuale o con il link in caso di preventivi generati automaticamente)'
    />
    {selectedSteps === '2' &&
      <>
        <CheckboxPanel
          checked={checkPubblicaLoStesso}
          onChange={setCheckPubblicaLoStesso}
          headerValue='Pubblica lo stesso'
          description='Se non vengono trovati risultati in uno dei servizi richiesti, i preventivi vengono comunque pubblicati'
        />
        <CheckboxPanel
          checked={checkControllaPreventivi}
          onChange={setCheckControllaPreventivi}
          headerValue='Controlla preventivi'
          description='Se attivo, i preventivi non vengono inviati al viaggiatore, ma vengono creati nello stato "in lavorazione" per essere visionati'
        />
      </>}
  </Flex>
)

export default Controlli
