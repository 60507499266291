import React, { useEffect, useState } from 'react'
import { Button, Card, Flex, Spinner } from '../../Components'
import { FC, invalidateQuery, useCQuery } from '../../Services'
import { useNavigate, useParams } from 'react-router-dom'
import HeaderSettings from './Components/HeaderSettings'
import FilterSections from './Components/FilterSections'
import StepChooseFormalita from './Components/StepChooseFormalita'
import StepChooseModeAI from './Components/StepChooseModeAI'
import { Editors } from './Components/Editors'
import { alert, prepareOptionsFilters, prepareServicePriority } from './Functions'
import { Divider } from 'primereact/divider'
import Controlli from './Components/Controlli'
import { useAuth } from '../../Provider/AuthProvider'

const prepareFilter = (filter, value, flagAbilitazione, valuesRimborso, valuesNoRimborso) => {
  const result = { ...filter, value }

  if (flagAbilitazione) {
    if (valuesRimborso !== null) {
      result.rimborsabili = valuesRimborso
    }
    if (valuesNoRimborso !== null) {
      result.nonRimborsabili = valuesNoRimborso
    }
  }

  return result
}

const prepareSelectedFilters = (selectedFilters, inputValues, flagAbilitazione, inputRimborso, inputNoRimborso) =>
  selectedFilters.map((filter, index) =>
    prepareFilter(filter, inputValues[index] || 0, flagAbilitazione, inputRimborso?.length ? inputRimborso[index] || 0 : null, inputNoRimborso?.length ? inputNoRimborso[index] || 0 : null))

const prepareDefaultPreset = async (defaultPreset, initialDefaultPreset, setInitialDefaultPreset) => {
  if (!initialDefaultPreset && defaultPreset) {
    const presetDefault = await FC.service('communityPreset').find({ query: { defaultPreset: 1 } })
    if (presetDefault[0]) {
      await FC.service('communityPreset').patch(presetDefault[0].id, { defaultPreset: 0, state: 0 })
      setInitialDefaultPreset(true)
    }
  }
}

export const CommunityPanel = () => {
  const { presetId, operation } = useParams()
  const { user = {} } = useAuth()
  const { data: [communityPreset] = [], isSuccess } = useCQuery(['communityPreset', presetId])
  const { data: flagAbilitazioneRimborsabile = false } = useCQuery(['flagAbilitazione', 'FLAG_ABILITAZIONE_RIMBORSABILE'])
  const { data: flagAbilitazioneAuto = false } = useCQuery(['flagAbilitazione', 'FLAG_ABILITAZIONE_AUTO'])
  const [flagAbilitazione, setFlagAbilitazione] = useState(false)
  const [titolo, setTitolo] = useState('')
  const [clientCode, setClientCode] = useState({ mastro: user.user?.environment?.codMastroCliente, gruppo: '', conto: '', sottoConto: '' })
  const [selectedClient, setSelectedClient] = useState()
  const [codiceGruppo, setCodiceGruppo] = useState('')
  const [descrizioneGruppo, setDescrizioneGruppo] = useState('')
  const [ragioneSociale, setRagioneSociale] = useState('')
  const [group, setGroup] = useState()
  const [selectedSteps, setSelectedSteps] = useState('0')
  const [selectedTone, setSelectedTone] = useState('formale')
  const [selectedPlaneFilters, setSelectedPlaneFilters] = useState([{ name: 'Prezzo', code: 'p' }])
  const [optionsPlaneFilters, setOptionsPlaneFilters] = useState([])
  const [inputPlaneValues, setInputPlaneValues] = useState([3])
  const [radiusPlane, setRadiusPlane] = useState(50)
  const [selectedTrainFilters, setSelectedTrainFilters] = useState([{ name: 'Prezzo', code: 'p' }])
  const [optionsTrainFilters, setOptionsTrainFilters] = useState([])
  const [inputTrainValues, setInputTrainValues] = useState([3])
  const [inputRimborsoTrain, setInputRimborsoTrain] = useState([3])
  const [inputNoRimborsoTrain, setInputNoRimborsoTrain] = useState([0])
  const [radiusTrain, setRadiusTrain] = useState(50)
  const [selectedHotelFilters, setSelectedHotelFilters] = useState([{ name: 'Prezzo', code: 'p' }, { name: 'Distanza', code: 'd' }])
  const [optionsHotelFilters, setOptionsHotelFilters] = useState([])
  const [inputHotelValues, setInputHotelValues] = useState([2, 1])
  const [radiusHotel, setRadiusHotel] = useState(50)
  const [selectedCarFilters, setSelectedCarFilters] = useState([{ name: 'Best buy', code: 'bb' }])
  const [optionsCarFilters, setOptionsCarFilters] = useState([])
  const [inputCarValues, setInputCarValues] = useState([3])
  const [userRedirectToOperatorMessage, setUserRedirectToOperatorMessage] = useState('')
  const [checkUserRedirectToOperatorMessage, setCheckUserRedirectToOperatorMessage] = useState(true)
  const [quotesMessage, setQuotesMessage] = useState('')
  const [initialMessages, setInitialMessages] = useState({})
  const [checkAutomaticHotel, setCheckAutomaticHotel] = useState(true)
  const [logicaCameraNonInPenale, setLogicaCameraNonInPenale] = useState(true)
  const [soloCamereNonInPenale, setSoloCamereNonInPenale] = useState(false)
  const [tolleranzaGiorniPenale, setTolleranzaGiorniPenale] = useState(3)
  const [servicePriority, setServicePriority] = useState([{ id: 'Business' }, { id: 'Premium' }, { id: 'Executive' }, { id: 'Standard' }])
  const [checkControlliIA, setCheckControlliIA] = useState(true)
  const [checkPubblicaLoStesso, setCheckPubblicaLoStesso] = useState(true)
  const [checkControllaPreventivi, setCheckControllaPreventivi] = useState(true)
  const [checkChiediMezzoDiTrasporto, setCheckChiediMezzoDiTrasporto] = useState(false)
  const [signNora, setSignNora] = useState(false)
  const [sendFinalMessage, setSendFinalMessage] = useState(false)
  const [checkMeteo, setCheckMeteo] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [defaultPreset, setDefaultPreset] = useState(false)
  const [initialDefaultPreset, setInitialDefaultPreset] = useState(false)
  const [statePreset, setStatePreset] = useState(1)
  const navigate = useNavigate([])

  const savePreset = async ({ groups } = {}, method) => {
    setIsSaving(true)
    const [group] = groups || []
    const selectedTrainFiltersPrepared = prepareSelectedFilters(selectedTrainFilters, inputTrainValues, flagAbilitazione, inputRimborsoTrain, inputNoRimborsoTrain)
    const selectedPlaneFiltersPrepared = prepareSelectedFilters(selectedPlaneFilters, inputPlaneValues)
    const selectedHotelFiltersPrepared = prepareSelectedFilters(selectedHotelFilters, inputHotelValues)
    const selectedCarFiltersPrepared = prepareSelectedFilters(selectedCarFilters, inputCarValues)
    const servicePriorityPrepared = await prepareServicePriority(servicePriority)
    await prepareDefaultPreset(defaultPreset, initialDefaultPreset, setInitialDefaultPreset)

    const requestData = {
      titolo,
      selectedSteps,
      selectedTone,
      selectedPlaneFilters: selectedPlaneFiltersPrepared,
      selectedTrainFilters: selectedTrainFiltersPrepared,
      selectedHotelFilters: selectedHotelFiltersPrepared,
      selectedCarFilters: selectedCarFiltersPrepared,
      checkAutomaticHotel,
      logicaCameraNonInPenale,
      soloCamereNonInPenale: logicaCameraNonInPenale ? soloCamereNonInPenale : false,
      tolleranzaGiorniPenale,
      userRedirectToOperatorMessage: checkUserRedirectToOperatorMessage ? userRedirectToOperatorMessage : '',
      checkUserRedirectToOperatorMessage,
      quotesMessage,
      servicePriority: servicePriorityPrepared,
      checkControlliIA,
      checkPubblicaLoStesso,
      checkControllaPreventivi,
      defaultPreset,
      checkMeteo,
      checkChiediMezzoDiTrasporto,
      signNora,
      sendFinalMessage,
      state: statePreset
    }

    requestData.codiceCliente = selectedClient ? selectedClient.codCliente12 : null
    requestData.ragioneSociale = selectedClient ? ragioneSociale : null

    if (defaultPreset) {
      requestData.codiceGruppo = ''
      requestData.descrizione = ''
    } else {
      requestData.codiceGruppo = !selectedClient ? codiceGruppo : null
      requestData.descrizione = !selectedClient ? group?.descrizione : null
    }

    const res = await FC.service('communityPreset')[method](
      ...[method === 'patch' ? presetId : undefined, requestData].filter(Boolean))

    setIsSaving(false)
    res?.id && navigate('/community/panel/modify/' + res?.id?.toString())
  }

  const save = async () => {
    try {
      const hasCodeOrDescription = codiceGruppo || descrizioneGruppo
      const isGroupValid = hasCodeOrDescription && group?.found
      const hasGroupCodes = group?.codice && group?.descrizione
      const areCodesMissing = !codiceGruppo && !descrizioneGruppo

      if (isGroupValid || hasGroupCodes || areCodesMissing) {
        if (quotesMessage && !quotesMessage.includes('#link')) {
          return alert('error', 'Link mancante', 'Nel messaggio per preventivi manca \'#link\'')
        }
        if (quotesMessage && checkMeteo && !quotesMessage.includes('#meteo')) {
          return alert('error', 'Info meteo mancanti', 'Nel messaggio per preventivi manca \'#meteo\'')
        }
        if (!titolo) {
          return alert('error', 'Titolo mancante')
        }

        const method = (presetId === 'new' || operation === 'duplicate') ? 'create' : 'patch'

        await savePreset(group || {}, method)

        invalidateQuery(['communityPreset', 'communityPresets'])
        alert('success', 'Salvataggio effettuato', '')
      } else {
        return alert('error', 'Errore', 'Gruppo non trovato')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const setSelectedFilters = (filters, setInputValues, setSelectedFilters, setInputRimborso, setInputNoRimborso) => {
    const inputValues = []
    const inputRimborso = []
    const inputNoRimborso = []
    const selectedFilters = filters.map(element => {
      inputValues.push(element.value)
      inputRimborso.push(element.rimborsabili)
      inputNoRimborso.push(element.nonRimborsabili)

      const { value, ...rest } = element
      return rest
    })

    setInputValues(inputValues)
    setInputRimborso && setInputRimborso(inputRimborso)
    setInputNoRimborso && setInputNoRimborso(inputNoRimborso)
    setSelectedFilters(selectedFilters)
  }

  useEffect(() => {
    setOptionsPlaneFilters(prepareOptionsFilters('plane'))
    setOptionsTrainFilters(prepareOptionsFilters('train'))
    setOptionsHotelFilters(prepareOptionsFilters('hotel'))
    setOptionsCarFilters(prepareOptionsFilters('car'))
    if (communityPreset) {
      const { titolo, codiceGruppo: codiceG, descrizione: descG, ragioneSociale, selectedSteps, selectedTone, selectedPlaneFilters, selectedCarFilters, selectedTrainFilters, selectedHotelFilters, checkAutomaticHotel, logicaCameraNonInPenale, soloCamereNonInPenale, tolleranzaGiorniPenale, redirectToOperatorMessage, quotesMessage, servicePriority, checkControlliIA, checkPubblicaLoStesso, checkControllaPreventivi, state } = communityPreset
      setTitolo(titolo)
      setClientCode({ mastro: communityPreset.codiceCliente?.slice(0, 2), gruppo: communityPreset.codiceCliente?.slice(2, 4), conto: communityPreset.codiceCliente?.slice(4, 6), sottoConto: communityPreset.codiceCliente?.slice(6, 12) })
      if (operation !== 'duplicate') {
        setCodiceGruppo(codiceG)
        setDescrizioneGruppo(descG)
      } else {
        setCodiceGruppo('')
        setDescrizioneGruppo('')
        setGroup()
      }
      setRagioneSociale(ragioneSociale)
      setSelectedSteps(selectedSteps?.toString())
      setSelectedTone(selectedTone)
      setSelectedFilters(selectedTrainFilters, setInputTrainValues, setSelectedTrainFilters, setInputRimborsoTrain, setInputNoRimborsoTrain)
      setSelectedFilters(selectedPlaneFilters, setInputPlaneValues, setSelectedPlaneFilters)
      setSelectedFilters(selectedHotelFilters, setInputHotelValues, setSelectedHotelFilters)
      setSelectedFilters(selectedCarFilters, setInputCarValues, setSelectedCarFilters)
      setLogicaCameraNonInPenale(logicaCameraNonInPenale === 1)
      setSoloCamereNonInPenale(soloCamereNonInPenale === 1)
      setTolleranzaGiorniPenale(tolleranzaGiorniPenale)
      setUserRedirectToOperatorMessage(userRedirectToOperatorMessage)
      setCheckUserRedirectToOperatorMessage(checkUserRedirectToOperatorMessage)
      setQuotesMessage(quotesMessage)
      setServicePriority(servicePriority)
      setCheckControlliIA(checkControlliIA === 1)
      setCheckAutomaticHotel(checkAutomaticHotel === 1)
      setCheckPubblicaLoStesso(checkPubblicaLoStesso === 1)
      setCheckControllaPreventivi(checkControllaPreventivi === 1)
      setStatePreset(state === 1)
      setInitialMessages({ redirectToOperatorMessage, quotesMessage })
      setCheckMeteo(communityPreset.checkMeteo === 1)
      setCheckChiediMezzoDiTrasporto(communityPreset.checkChiediMezzoDiTrasporto === 1)
      setSignNora(communityPreset.signNora === 1)
      setSendFinalMessage(communityPreset.sendFinalMessage === 1)
      if (operation === 'duplicate') {
        setDefaultPreset(false)
        setInitialDefaultPreset(false)
      } else {
        setDefaultPreset(communityPreset.defaultPreset === 1)
        setInitialDefaultPreset(communityPreset.defaultPreset === 1)
      }
      if (codiceG && descG && operation !== 'duplicate') {
        setGroup({ codice: codiceG, descrizione: descG })
      }
    }
    if (flagAbilitazioneRimborsabile) {
      setFlagAbilitazione(flagAbilitazioneRimborsabile)
    }
  }, [communityPreset, flagAbilitazioneRimborsabile])

  if (!isSuccess) return <Spinner />

  const isNewPreset = presetId === 'new'
  const isDuplicatePreset = operation === 'duplicate'
  const isExistingPreset = !isNewPreset && !isDuplicatePreset

  const groupOrDefault = (group || defaultPreset)

  const presetNewCondition = isNewPreset && groupOrDefault
  const presetModifyCondition = isExistingPreset && groupOrDefault
  const presetDuplicateCondition = isDuplicatePreset && groupOrDefault

  const visibilityCondition = presetNewCondition || presetModifyCondition || presetDuplicateCondition
  const { b4bEnabled = 0 } = user.user?.environment // disabilito la scelta dei preventivi qualora non ci sia il b4b
  return (
    <Flex>
      <Card style={{ width: '80%' }}>
        <HeaderSettings
          presetId={presetId} clientCode={[clientCode, setClientCode]}
          selectedClient={[selectedClient, setSelectedClient]} save={save}
          codiceGruppo={[codiceGruppo, setCodiceGruppo]}
          ragioneSociale={[ragioneSociale, setRagioneSociale]}
          descrizioneGruppo={[descrizioneGruppo, setDescrizioneGruppo]}
          group={[group, setGroup]} titolo={[titolo, setTitolo]}
          defaultPreset={[defaultPreset, setDefaultPreset]}
          initialDefaultPreset={[initialDefaultPreset, setInitialDefaultPreset]}
          operation={operation}
          visibilityCondition={visibilityCondition}
          statePreset={[statePreset, setStatePreset]}
        />
        {visibilityCondition && (
          <>
            <StepChooseFormalita selectedTone={[selectedTone, setSelectedTone]} />
            <StepChooseModeAI selectedSteps={[selectedSteps, setSelectedSteps]} b4bEnabled={b4bEnabled} />
            {selectedSteps === '2' && // condizionato solamente se step è con creazione di preventivo
              <FilterSections
                plane={{
                  selectedFilters: [selectedPlaneFilters, setSelectedPlaneFilters],
                  optionsFilters: [optionsPlaneFilters, setOptionsPlaneFilters],
                  inputValues: [inputPlaneValues, setInputPlaneValues],
                  radius: [radiusPlane, setRadiusPlane]
                }}
                train={{
                  selectedFilters: [selectedTrainFilters, setSelectedTrainFilters],
                  optionsFilters: [optionsTrainFilters, setOptionsTrainFilters],
                  inputValues: [inputTrainValues, setInputTrainValues],
                  inputRimborso: [inputRimborsoTrain, setInputRimborsoTrain],
                  inputNoRimborso: [inputNoRimborsoTrain, setInputNoRimborsoTrain],
                  radius: [radiusTrain, setRadiusTrain],
                  servicePriority: [servicePriority, setServicePriority],
                  flagAbilitazione: [flagAbilitazione, setFlagAbilitazione]
                }}
                car={{
                  selectedFilters: [selectedCarFilters, setSelectedCarFilters],
                  optionsFilters: [optionsCarFilters, setOptionsCarFilters],
                  inputValues: [inputCarValues, setInputCarValues]
                }}
                hotel={{
                  selectedFilters: [selectedHotelFilters, setSelectedHotelFilters],
                  optionsFilters: [optionsHotelFilters, setOptionsHotelFilters],
                  inputValues: [inputHotelValues, setInputHotelValues],
                  radius: [radiusHotel, setRadiusHotel],
                  logicaCameraNonInPenale: [logicaCameraNonInPenale, setLogicaCameraNonInPenale],
                  soloCamereNonInPenale: [soloCamereNonInPenale, setSoloCamereNonInPenale],
                  tolleranzaGiorniPenale: [tolleranzaGiorniPenale, setTolleranzaGiorniPenale]
                }}
                flagAbilitazioneAuto={flagAbilitazioneAuto}
              />}

            <Editors
              userRedirectToOperatorMessage={[userRedirectToOperatorMessage, setUserRedirectToOperatorMessage]}
              checkUserRedirectToOperatorMessage={[checkUserRedirectToOperatorMessage, setCheckUserRedirectToOperatorMessage]}
              checkMeteo={checkMeteo}
              quotesMessage={[quotesMessage, setQuotesMessage]}
              initialMessages={initialMessages}
              selectedSteps={selectedSteps}
            />
            <Divider />
            <Controlli
              checkAutomaticHotel={[checkAutomaticHotel, setCheckAutomaticHotel]}
              checkControlliIA={[checkControlliIA, setCheckControlliIA]}
              checkPubblicaLoStesso={[checkPubblicaLoStesso, setCheckPubblicaLoStesso]}
              checkControllaPreventivi={[checkControllaPreventivi, setCheckControllaPreventivi]}
              checkChiediMezzoDiTrasporto={[checkChiediMezzoDiTrasporto, setCheckChiediMezzoDiTrasporto]}
              signNora={[signNora, setSignNora]}
              sendFinalMessage={[sendFinalMessage, setSendFinalMessage]}
              selectedSteps={selectedSteps}
              checkMeteo={[checkMeteo, setCheckMeteo]}
            />
            <Divider />
            <Flex fw js as>
              <Button label='Salva' style={{ width: 120, marginTop: 20, display: visibilityCondition ? 'flex' : 'none' }} onClick={save} disabled={isSaving} />
            </Flex>
          </>)}
      </Card>
    </Flex>
  )
}
