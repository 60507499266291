import Papa from 'papaparse'
import { FC } from '../../../../Services/FeathersClient'

const getLabel = (key) => {
  const labels = ['pid', 'email', 'phone', 'clientId', 'name', 'surname']
  return labels[key]
}

export const readClients = async (e) => {
  const parseFile = (e) =>
    new Promise(resolve => {
      Papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: ({ data }) => resolve(data)
      })
    })
  const parsedData = await parseFile(e)
  return parsedData
}

export const uploadClients = async (clients) => {
  const clientsToUpload = clients.map((client) => {
    const obj = { }
    Object.keys(client).forEach((key, ind) => {
      obj[getLabel(ind)] = client[key]
    })
    return obj
  })
  window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Importazione viaggiatori iniziata' })
  try {
    for (const client of clientsToUpload) { await FC.service('travellers').create(client) }
  } catch (e) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore durante l\'importazione dei viaggiatori' })
    return false
  }
  window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Viaggiatori importati con successo' })
  return true
}
